var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-auth__form b-step__activity"
  }, [_vm._m(0), _c('div', {
    staticClass: "b-step__inner"
  }, [!_vm.activityIsLoaded ? [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Загрузка..."
    }
  })] : [_c('div', {
    staticClass: "card"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submitHandler.apply(null, arguments);
      }
    }
  }, [_c('ul', {
    staticClass: "b-step__activity-list d-flex"
  }, [_c('b-form-checkbox-group', {
    model: {
      value: _vm.selectedActivitys,
      callback: function ($$v) {
        _vm.selectedActivitys = $$v;
      },
      expression: "selectedActivitys"
    }
  }, _vm._l(_vm.activityList, function (activity, index) {
    return _c('li', {
      key: index
    }, [_c('b-form-checkbox', {
      attrs: {
        "value": activity.id
      }
    }, [_vm._v(" " + _vm._s(activity.name) + " ")])], 1);
  }), 0)], 1)])], 1)], _c('div', {
    staticClass: "b-btns__wrapper"
  }, [_c('b-button', {
    staticClass: "w-100 center",
    attrs: {
      "variant": "primary",
      "disabled": _vm.selectedActivitys.length < 1
    },
    on: {
      "click": _vm.next_step_handler
    }
  }, [_vm._v(" Продолжить ")])], 1)], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "head-text"
  }, [_c('h1', [_vm._v("Виды деятельности")]), _c('p', [_vm._v("Выберите виды своей деятельности")])]);

}]

export { render, staticRenderFns }