var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inner"
  }, [_vm._m(0), _c('div', {
    staticClass: "b-storyline"
  }, [_c('ul', [_vm._m(1), _vm._l(_vm.steps, function (step, index) {
    return _c('li', {
      key: index,
      class: [_vm.storyline[step.name] ? 'active' : null, _vm.currentStep === step.name ? 'active-step' : null],
      on: {
        "click": function ($event) {
          return _vm.select_story(step);
        }
      }
    }, [_c('div', {
      staticClass: "b-storyline__title"
    }, [_vm._v(_vm._s(step.title))]), _c('div', {
      staticClass: "b-storyline__status"
    }, [_vm._v(" " + _vm._s(_vm.storyline[step.name] ? 'Заполнено' : '') + " ")])]);
  })], 2)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "head-text",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../../public/img/icons/newLogo.svg")
    }
  }), _c('p', [_vm._v("Веб-приложение для автоматизации торговых процессов магазинов")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "active cursor-none"
  }, [_c('div', {
    staticClass: "b-storyline__title"
  }, [_vm._v("Создание аккаунта")]), _c('div', {
    staticClass: "b-storyline__status"
  }, [_vm._v("Заполнено")])]);

}]

export { render, staticRenderFns }