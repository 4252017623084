var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-auth__form b-step__company"
  }, [_c('div', {
    staticClass: "head-text center"
  }, [_c('h1', [_vm._v(_vm._s(_vm.company_title))]), _c('p', [_vm._v(" Компания - торговое предприятие, которое объединяет ваши магазины. Организовать их структуру вы сможете сразу после завершения регистрации ")])]), _c('div', {
    staticClass: "b-step__company-form"
  }, [_c('add-company-form')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }