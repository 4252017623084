var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form', {
    staticClass: "form-company",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submitHandler.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v("Введите данные одного из ваших юрлиц")])])]), _c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('label', [_vm._v(" ИНН "), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "required": "",
      "min-length": "10",
      "max-length": "12"
    },
    on: {
      "keyup": _vm.search_entity
    },
    model: {
      value: _vm.entity.inn,
      callback: function ($$v) {
        _vm.$set(_vm.entity, "inn", $$v);
      },
      expression: "entity.inn"
    }
  }), _vm.search_result ? [_c('apollo-query', {
    attrs: {
      "query": require("../../gql/dadata.gql"),
      "variables": {
        inn: _vm.entity.inn
      },
      "skip": _vm.entity.inn < 10,
      "fetch-policy": "no-cache"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var _ref$result = _ref.result,
            error = _ref$result.error,
            data = _ref$result.data,
            isLoading = _ref.isLoading;
        return [isLoading ? _c('div', {
          staticClass: "b-search-result p-2 center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary"
          }
        })], 1) : error ? _c('div', {
          staticClass: "error apollo"
        }, [_vm._v(" Ошибка при запросе ")]) : data ? _c('div', {
          staticClass: "b-search-result"
        }, _vm._l(data.Dadata, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "item cursor",
            on: {
              "click": function ($event) {
                return _vm.select_entity(item);
              }
            }
          }, [_c('div', {
            staticClass: "item-name"
          }, [_vm._v(_vm._s(item.name))]), _c('div', {
            staticClass: "item-info"
          }, [_vm._v(" ИНН: " + _vm._s(item.inn) + " КПП: " + _vm._s(item.kpp) + " " + _vm._s(item.address_entity.city) + ", " + _vm._s(item.address_entity.country) + ", " + _vm._s(item.address_entity.post_index) + ", улица " + _vm._s(item.address_entity.street) + ", " + _vm._s(item.address_entity.apartment) + " ")])]);
        }), 0) : _c('div', {
          staticClass: "b-search-result p-3 center"
        }, [_vm._v(" Нет результата ")])];
      }
    }], null, false, 194271516)
  })] : _vm._e()], 2), _c('div', {
    staticClass: "col-6"
  }, [_c('label', [_vm._v("КПП")]), _c('b-form-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.entity.kpp,
      callback: function ($$v) {
        _vm.$set(_vm.entity, "kpp", $$v);
      },
      expression: "entity.kpp"
    }
  })], 1)]), _c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('label', [_vm._v("Название компании")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.entity.name,
      callback: function ($$v) {
        _vm.$set(_vm.entity, "name", $$v);
      },
      expression: "entity.name"
    }
  })], 1)]), _c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('label', [_vm._v("Название магазина")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.branch_name,
      callback: function ($$v) {
        _vm.branch_name = $$v;
      },
      expression: "branch_name"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('b-button', {
    staticClass: "w-100 center",
    attrs: {
      "block": "",
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.entity.name.length < 2 || _vm.is_loading || _vm.branch_name.length < 2
    }
  }, [_vm.is_loading ? [_c('b-spinner', {
    staticStyle: {
      "height": "20px",
      "width": "20px"
    },
    attrs: {
      "variant": "light"
    }
  })] : [_vm._v("Продолжить")]], 2)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }