<template>
  <div class="b-auth__form b-step__activity">
    <div class="head-text">
      <h1>Виды деятельности</h1>
      <p>Выберите виды своей деятельности</p>
    </div>
    <div class="b-step__inner">
      <template v-if="!activityIsLoaded">
        <b-spinner
          variant="primary"
          label="Загрузка..."
        />
      </template>
      <template v-else>
        <div class="card">
          <b-form @submit.prevent="submitHandler">
            <ul class="b-step__activity-list d-flex">
              <b-form-checkbox-group v-model="selectedActivitys">
                <li
                  v-for="(activity, index) in activityList"
                  :key="index"
                >
                  <b-form-checkbox :value="activity.id">
                    {{ activity.name }}
                  </b-form-checkbox>
                </li>
              </b-form-checkbox-group>
            </ul>
          </b-form>
        </div>
      </template>
      <div class="b-btns__wrapper">
        <b-button
          variant="primary"
          :disabled="selectedActivitys.length < 1"
          class="w-100 center"
          @click="next_step_handler"
        >
          Продолжить
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    data: () => ({
      selectedActivitys: [],
      is_fasol: false
    }),

    computed: {
      ...mapGetters({
        activityList: 'core/getActivityList',
        activityIsLoaded: 'core/activityIsLoaded',
        user: 'core/user',
        getRegflowCompany: 'core/getRegflowCompany'
      })
    },

    watch: {
      selectedActivitys() {
        this.setRegflowCompany({ ...this.getRegflowCompany, activities: this.selectedActivitys })
      }
    },

    created() {
      this.get_activitys_list()
      if (this.getRegflowCompany?.activities?.length) {
        this.selectedActivitys = this.getRegflowCompany?.activities
      }
    },

    methods: {
      ...mapActions({
        setFranchise: 'core/setFranchise',
        set_activities: 'core/setActivities',
        get_activitys_list: 'core/getActivitysList',
        setRegflowCompany: 'core/setRegflowCompany'
      }),

      select_activity(activity) {
        const id = activity.id
        const status = activity.active
        const index = this.selectedActivitys.indexOf(id)

        if (status) this.selectedActivitys.push(id)
        if (!status && index > -1) this.selectedActivitys.splice(index, 1)
      },

      prev_step() {
        this.$router.push({ name: 'registration' })
      },

      next_step_handler() {
        this.set_activities(this.selectedActivitys)
        this.setFranchise(this.is_fasol)
      },

      submitHandler() {}
    }
  }
</script>

<style scoped lang="scss">
  .b-auth__form.b-step__activity {
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  ::v-deep() {
    .b-auth {
      .spinner-border {
        margin-top: 52px;
      }

      .custom-checkbox,
      .custom-checkbox label {
        cursor: pointer;
      }
    }
  }
</style>
