<template>
  <div class="b-auth__form b-step__company">
    <div class="head-text center">
      <h1>{{ company_title }}</h1>
      <p>
        Компания - торговое предприятие, которое объединяет ваши магазины. Организовать их структуру вы сможете сразу
        после завершения регистрации
      </p>
    </div>

    <div class="b-step__company-form">
      <add-company-form />
    </div>
  </div>
</template>

<script>
  import AddCompanyForm from '../components/company/AddCompanyForm'

  export default {
    components: {
      AddCompanyForm
    },

    data: function () {
      return {
        step: {
          add_company: true
        },
        company_title: 'Добавление компании'
      }
    },

    methods: {
      prev_step() {
        this.$emit('changeStep', 'activity')
        this.$router.push({ name: 'registr.activity' })
      }
    }
  }
</script>

<style scoped lang="scss">
  .b-auth__form.b-step__company {
    padding: 0;
    max-width: 555px;
  }
</style>
