var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-confirm"
  }, [_c('div', {
    staticClass: "confirm-block"
  }, [_vm._m(0), _c('div', {
    staticClass: "confirm-block__email"
  }, [_vm._m(1), _c('e-input', {
    staticClass: "email-confirm",
    staticStyle: {
      "font-size": "16px !important"
    },
    attrs: {
      "search-icon": false,
      "placeholder": "E-mail",
      "label": "E-mail"
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('e-button', {
    staticClass: "send",
    attrs: {
      "size": "l",
      "variant": _vm.variant,
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.send
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")])], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "confirm-block__text"
  }, [_c('h1', [_vm._v("Подтвердите E-mail")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info"
  }, [_c('p', [_vm._v(" Мы отправили вам письмо. Пожалуйста, проверьте почту и завершите регистрацию, перейдя по кнопке в письме ")]), _c('div', {
    staticClass: "line"
  }), _c('b', [_vm._v("Не пришло письмо?")]), _c('ul', [_c('li', [_vm._v("Проверьте папку спам")]), _c('li', [_vm._v("Проверьте корректность указанного E-mail")])])]);

}]

export { render, staticRenderFns }