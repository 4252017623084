<template>
  <div class="inner">
    <div
      class="head-text"
      style="text-align: center"
    >
      <img src="../../../../public/img/icons/newLogo.svg" />
      <p>Веб-приложение для автоматизации торговых процессов магазинов</p>
    </div>
    <div class="b-storyline">
      <ul>
        <li class="active cursor-none">
          <div class="b-storyline__title">Создание аккаунта</div>
          <div class="b-storyline__status">Заполнено</div>
        </li>
        <li
          v-for="(step, index) in steps"
          :key="index"
          :class="[storyline[step.name] ? 'active' : null, currentStep === step.name ? 'active-step' : null]"
          @click="select_story(step)"
        >
          <div class="b-storyline__title">{{ step.title }}</div>
          <div class="b-storyline__status">
            {{ storyline[step.name] ? 'Заполнено' : '' }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    data: function () {
      return {
        steps: [
          {
            title: 'Виды деятельности',
            name: 'activity'
          },
          {
            title: 'Добавление компании',
            name: 'company'
          },
          {
            title: 'Подтверждение E-mail',
            name: 'confirm'
          }
        ]
      }
    },

    computed: {
      ...mapGetters({
        currentStep: 'core/getCurrentStep',
        storyline: 'core/getStorylineStatus'
      })
    },

    methods: {
      ...mapActions({
        setCurrentStep: 'core/setCurrentStep'
      }),

      select_story(step) {
        this.setCurrentStep(step.name)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .cursor-none {
    cursor: default !important;
  }
</style>
