var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-auth__process d-flex w-100"
  }, [_c('div', {
    staticClass: "b-auth__storyline"
  }, [_c('story-line', {
    on: {
      "selectedStep": _vm.changeStep
    }
  })], 1), _c('div', {
    staticClass: "b-auth__step d-flex"
  }, [_c('div', {
    staticClass: "b-auth__faq"
  }, [_vm._v(" Есть проблемы? "), _c('router-link', {
    attrs: {
      "to": "#"
    }
  }, [_vm._v("Задайте вопрос")])], 1), _vm.step === 'activity' ? [_c('activity')] : _vm._e(), _vm.step === 'company' ? [_c('company', {
    on: {
      "changeStep": _vm.changeStep
    }
  })] : _vm._e(), _vm.step === 'confirm' ? [_c('confirm-email', {
    on: {
      "changeStep": _vm.changeStep
    }
  })] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }