<template>
  <div class="b-auth__process d-flex w-100">
    <div class="b-auth__storyline">
      <story-line @selectedStep="changeStep" />
    </div>

    <div class="b-auth__step d-flex">
      <div class="b-auth__faq">
        Есть проблемы?
        <router-link to="#">Задайте вопрос</router-link>
      </div>
      <template v-if="step === 'activity'">
        <activity />
      </template>

      <template v-if="step === 'company'">
        <company @changeStep="changeStep" />
      </template>

      <template v-if="step === 'confirm'">
        <confirm-email @changeStep="changeStep" />
      </template>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import StoryLine from './steps/Storyline'
  import Activity from './steps/Activity'
  import Company from './steps/Company'
  import ConfirmEmail from '@/views/auth/steps/ConfirmEmail'

  export default {
    components: {
      ConfirmEmail,
      Activity,
      Company,
      StoryLine
    },

    computed: {
      ...mapGetters({
        step: 'core/getCurrentStep'
      })
    },

    watch: {},

    async mounted() {
      const { step } = await this.getRegisterStep()
      if (step) {
        if (step === 'company') {
          return await this.setStoryLineStatus('activity')
        }
        await this.setStoryLineStatus(step)
      }
    },

    methods: {
      ...mapActions({ setStoryLineStatus: 'core/setStoryLineStatus', setIsConfirmed: 'core/setIsConfirmed' }),
      async getRegisterStep() {
        const { data } = await this.$apollo.query({
          query: require('./gql/GetCurrentRegistrationStep.graphql'),
          fetchPolicy: 'no-cache'
        })
        return data?.GetCurrentRegistrationStep
      },
      changeStep(step) {
        if (step === 'company') this.$router.push({ name: 'registr.company' })
        if (step === 'confirm') this.$router.push({ name: 'registr.confirm' })

        this.step = step
      }
    }
  }
</script>
