<template>
  <b-form
    class="form-company"
    @submit.prevent="submitHandler"
  >
    <div class="row">
      <div class="col-12">
        <div class="form-title">Введите данные одного из ваших юрлиц</div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-6">
        <label>
          ИНН
          <span class="required">*</span>
        </label>
        <b-form-input
          v-model="entity.inn"
          type="text"
          required
          min-length="10"
          max-length="12"
          @keyup="search_entity"
        />
        <template v-if="search_result">
          <apollo-query
            :query="require('../../gql/dadata.gql')"
            :variables="{ inn: entity.inn }"
            :skip="entity.inn < 10"
            fetch-policy="no-cache"
          >
            <template #default="{ result: { error, data }, isLoading }">
              <div
                v-if="isLoading"
                class="b-search-result p-2 center"
              >
                <b-spinner variant="primary" />
              </div>
              <div
                v-else-if="error"
                class="error apollo"
              >
                Ошибка при запросе
              </div>
              <div
                v-else-if="data"
                class="b-search-result"
              >
                <div
                  v-for="(item, index) in data.Dadata"
                  :key="index"
                  class="item cursor"
                  @click="select_entity(item)"
                >
                  <div class="item-name">{{ item.name }}</div>
                  <div class="item-info">
                    ИНН: {{ item.inn }} КПП: {{ item.kpp }} {{ item.address_entity.city }},
                    {{ item.address_entity.country }}, {{ item.address_entity.post_index }}, улица
                    {{ item.address_entity.street }},
                    {{ item.address_entity.apartment }}
                  </div>
                </div>
              </div>
              <div
                v-else
                class="b-search-result p-3 center"
              >
                Нет результата
              </div>
            </template>
          </apollo-query>
        </template>
      </div>
      <div class="col-6">
        <label>КПП</label>
        <b-form-input
          v-model="entity.kpp"
          type="text"
        />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <label>Название компании</label>
        <b-form-input
          v-model="entity.name"
          type="text"
          required
        />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <label>Название магазина</label>
        <b-form-input
          v-model="branch_name"
          type="text"
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-button
          block
          type="submit"
          variant="primary"
          class="w-100 center"
          :disabled="entity.name.length < 2 || is_loading || branch_name.length < 2"
        >
          <template v-if="is_loading">
            <b-spinner
              variant="light"
              style="height: 20px; width: 20px"
            />
          </template>
          <template v-else>Продолжить</template>
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
  import * as R from 'ramda'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    data: () => ({
      is_loading: false,
      search_result: false,
      entity: {
        name: '',
        inn: '',
        kpp: ''
      },
      branch_name: ''
    }),

    computed: {
      ...mapGetters({
        getActivities: 'core/getActivitiesId',
        get_is_fasol: 'core/get_is_fasol',
        getCompanyName: 'core/getCompanyName',
        getRegflowCompany: 'core/getRegflowCompany'
      })
    },

    watch: {
      'entity.name'() {
        this.setRegflowCompany({ ...this.getRegflowCompany, name: this.entity.name })
      },
      'entity.inn'() {
        this.setRegflowCompany({ ...this.getRegflowCompany, inn: this.entity.inn })
      },
      'entity.kpp'() {
        this.setRegflowCompany({ ...this.getRegflowCompany, kpp: this.entity.kpp })
      },
      branch_name() {
        this.setRegflowCompany({ ...this.getRegflowCompany, nameBranch: this.branch_name })
      },
      getCompanyName(newVal, oldVal) {
        if (newVal !== oldVal) this.$router.push({ name: 'dashboard' })
      }
    },

    mounted() {
      if (this.getRegflowCompany?.name) {
        this.entity.name = this.getRegflowCompany?.name
      }
      if (this.getRegflowCompany?.inn) {
        this.entity.inn = this.getRegflowCompany?.inn
      }
      if (this.getRegflowCompany?.kpp) {
        this.entity.kpp = this.getRegflowCompany?.kpp
      }
      if (this.getRegflowCompany?.nameBranch) {
        this.branch_name = this.getRegflowCompany?.nameBranch
      }
    },

    methods: {
      ...mapActions({
        createCompany: 'core/createCompany',
        setCurrStep: 'core/setCurrentStep',
        setStoryLineStatus: 'core/setStoryLineStatus',
        setRegflowCompany: 'core/setRegflowCompany'
      }),

      async submitHandler() {
        this.is_loading = true
        const params = {
          name: this.entity.name,
          activities: this.getActivities,
          franchise: this.get_is_fasol ? 'fasol' : null,
          entity: this.entity,
          branch_name: this.branch_name
        }

        delete params.entity.id

        await this.createCompany(params)

        await this.getRegisterStep()

        this.setCurrStep('confirm')
        await this.setStoryLineStatus('confirm')
      },

      async getRegisterStep() {
        const { data } = await this.$apollo.query({
          query: require('../../gql/GetCurrentRegistrationStep.graphql'),
          fetchPolicy: 'no-cache'
        })
        return data?.GetCurrentRegistrationStep
      },

      search_entity() {
        if (this.entity.inn.length > 9) return (this.search_result = true)

        this.search_result = false
      },

      select_entity(item) {
        this.entity = R.mergeLeft(item, this.entity)
        this.search_result = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep() {
    form {
      label {
        margin-bottom: 6px;
        display: block;
        line-height: 16px;
      }

      input {
        height: 42px !important;
        font-size: 16px !important;
      }
    }
    button {
      justify-content: center;
      height: 42px;
    }
  }

  .form-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #313131;
    margin-bottom: 15px;
  }

  .b-search-result {
    position: absolute;
    z-index: 10;
    top: 75px;
    left: 15px;
    width: 490px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .item {
      padding: 8px 14px;

      &-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #313131;
      }

      &-info {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        height: 24px;
        overflow: hidden;

        span {
          margin-right: 12px;
        }
      }
    }
  }
</style>
