<template>
  <div class="container-confirm">
    <div class="confirm-block">
      <div class="confirm-block__text">
        <h1>Подтвердите E-mail</h1>
      </div>
      <div class="confirm-block__email">
        <div class="info">
          <p>
            Мы отправили вам письмо. Пожалуйста, проверьте почту и завершите регистрацию, перейдя по кнопке в письме
          </p>
          <div class="line"></div>
          <b>Не пришло письмо?</b>
          <ul>
            <li>Проверьте папку спам</li>
            <li>Проверьте корректность указанного E-mail</li>
          </ul>
        </div>
        <e-input
          v-model="email"
          style="font-size: 16px !important"
          :search-icon="false"
          class="email-confirm"
          placeholder="E-mail"
          label="E-mail"
        />
        <e-button
          class="send"
          size="l"
          :variant="variant"
          :disabled="disabled"
          @click="send"
        >
          {{ buttonText }}
        </e-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ConfirmEmail',
    data() {
      return {
        date: null,
        buttonText: 'Отправить повторно',
        disabled: false,
        interval: null,
        email: ''
      }
    },
    computed: {
      ...mapGetters({ getUser: 'core/user' }),
      variant() {
        if (this.interval) return ''
        return 'primary'
      }
    },
    watch: {
      'getUser.email'() {
        this.email = this.getUser.email
      }
    },
    mounted() {
      if (localStorage.getItem('interval_register_confirm')) {
        this.disabled = true
        this.date = localStorage.getItem('interval_register_confirm')
        this.timer()
      }
      this.email = this.getUser.email
    },
    methods: {
      send() {
        this.date = new Date().setMinutes(new Date().getMinutes() + 1)
        localStorage.setItem('interval_register_confirm', this.date.toString())
        this.timer()
        this.$apollo.query({
          query: require('../gql/ResetMail.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              email: this.email
            }
          }
        })
      },
      timer() {
        this.disabled = true
        this.interval = setInterval(() => {
          if (this.date > new Date()) {
            this.buttonText = `Отправить повторно: ${Math.round((this.date - new Date()) / 1000)} сек.`
          } else {
            this.disabled = false
            this.buttonText = 'Отправить повторно'
            clearInterval(this.interval)
            localStorage.removeItem('interval_register_confirm')
            this.interval = null
          }
        }, 100)
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .input {
    height: 42px !important;
    font-size: 16px !important;

    &::placeholder {
      font-size: 16px;
    }
  }

  .container-confirm {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .confirm-block {
      width: 555px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__text {
        width: 438px;

        h1 {
          font-family: 'Gilroy-Bold', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 44px;
          line-height: 52px;
          text-align: center;
          color: #313131;
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          text-align: center;
          color: #888888;
        }
      }

      &__email {
        .info {
          .line {
            margin: 22px 0;
            height: 2px;
            width: 100%;
            background: #e1e1e1;
          }
          p {
            margin: 0;
            font-family: 'Gilroy-Bold', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #313131;
          }
          b {
            font-family: 'Gilroy-Medium', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #313131;
            margin-bottom: 22px;
          }
          ul {
            margin-bottom: 22px;
            list-style: inside !important;
            li {
              list-style: inside !important;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              align-items: center;
              color: #313131;
              &:first-child {
                margin-bottom: 8px;
              }
            }
          }
        }
        width: 100%;
        padding: 32px;
        border: 1px solid #e2e2e2;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        margin-top: 36px;
        .send {
          margin-top: 20px;
          width: 100%;
        }
      }
    }
  }
</style>
